import { useEffect, useState } from "react";
import { Button, Form, message, Input, InputNumber, Select } from "antd";
import { Top } from "../../components/Top";
import { useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../utils/hooks";

import { useNavigate } from "react-router-dom";
import { CREATE_ONE_OBJECT, SINGLE_UPLOAD } from "../../gqls";
import { FIND_MANY_OBJECT_TYPE } from "../../gqls/objecttype";
import { FIND_MANY_CITIES } from "../../gqls/cities";

const { TextArea } = Input;
const { Option } = Select;

const CreateObject = () => {
  const { user, loading: loadingMe } = useUser();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!user?.role?.canObject) navigate(-1);
  });

  const [createOneObject, { loading }] = useMutation(CREATE_ONE_OBJECT, {
    onCompleted: () => navigate("/objects"),
    onError: (err) => {
      console.error(err);
      message.error("Не удалось выполнить запрос");
    },
  });

  const { data: dataType, loading: loadType } = useQuery(FIND_MANY_OBJECT_TYPE);
  const { data: dataCity, loading: loadCity } = useQuery(FIND_MANY_CITIES);

  const [upload] = useMutation(SINGLE_UPLOAD);

  const handleCreate = (v) => {
    console.log(v,'V')
    // createOneObject({ variables: { data: { ...v, images: images } } });
    createOneObject({ variables: 
      { data: 
        { 
          title: v.title,
          sellType: v.sellType,
          objectType:{
            connect:{
              id:v.type
            }
          },
          description: v.description,
          about: v.about,
          developer: v.developer,
          address: v.address,
          space: v.space,
          spaceFt: v.spaceFt,
          rooms: v.rooms,
          bedrooms: v.bedrooms,
          bathrooms: v.bathrooms,
          costRUB: v.costRUB,
          costUSD: v.costUSD,
          costAED: v.costAED,
          objectCity: {
            connect:{
              id:v.city
            }
          },
          images: images
        }
      }
    });
  };

  const handleImages = (event) => {
    const {
      validity,
      files: [file],
    } = event.target;
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: async (data) => {
          setImages([
            ...images,
            "http://api.tuimaadare.com/upload/" + data.singleUpload,
          ]);
        },
        onError: (er) => console.log(er),
      });
    }
  };

  if (loadingMe || loadType || loadCity) return "Загрузка...";
  if (!user?.role?.canObject) return "Ошибка";

  return (
    <>
      <Top title="Добавить объект" />
      <div style={{ maxWidth: 500 }}>
        <Form onFinish={handleCreate} layout="vertical">
          <Form.Item name="title" label="Заголовок" required>
            <Input />
          </Form.Item>
          <Form.Item name='sellType' label='Тип продажи' required>
            <Select placeholder='Выберите тип продажи объекта'>
              <Option value={"OFFPLAN"}>Оффплан</Option>
              <Option value={"SECONDARY"}>Вторичка</Option>
              <Option value={"RENT"}>Аренда</Option>
            </Select>
          </Form.Item>
          <Form.Item name="type" label="Тип" required>
            <Select placeholder="Выберите тип объекта">
              {dataType?.findManyObjectType?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.title}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item name="description" label="Описание" required>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="about" label="Об объекте" required>
            <Input />
          </Form.Item>
          <Form.Item name="developer" label="Застройщик" required>
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Адрес" required>
            <Input />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Form.Item name="space" label="Площадь (в м2)" required>
                <InputNumber />
              </Form.Item>
              <Form.Item name="rooms" label="Комнат" required>
                <InputNumber />
              </Form.Item>
            </div>
            <div>
              <div style={{height:86}}></div>
            <Form.Item name="bedrooms" label="Спален" required>
                <InputNumber />
              </Form.Item>
            </div>
            <div>
            <Form.Item name="spaceFt" label="Площадь (в ft2)" required>
                <InputNumber />
              </Form.Item>
   
              <Form.Item name="bathrooms" label="Санузлов" required>
                <InputNumber />
              </Form.Item>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item name="costRUB" label="Цена в рублях" required>
              <InputNumber />
            </Form.Item>
            <Form.Item name="costUSD" label="Цена в долларах" required>
              <InputNumber />
            </Form.Item>
            <Form.Item name="costAED" label="Цена в дирхамах" required>
              <InputNumber />
            </Form.Item>
          </div>
          {/* <Form.Item name="city" label="Город" required>
            <Select placeholder="Выберите город">
              <Option value={"DUBAI"}>ДУБАЙ</Option>
              <Option value={"MOSCOW"}>МОСКВА</Option>
            </Select>
          </Form.Item> */}
          <Form.Item name="city" label="Город" required>
            <Select placeholder="Выберите город">
              {dataCity?.findManyCities?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.title}</Option>;
              })}
            </Select>
          </Form.Item>

          <div>
            <label>Изображения:</label>
            <input
              onChange={handleImages}
              style={{ width: 96 }}
              accept=".png, .jpg, .jpeg"
              name="myFile"
              type="file"
            />
            {images && (
              <div style={{ marginBottom: 12, marginTop: 12, display: "flex" }}>
                {images?.map((item, index) => {
                  return (
                    <a
                      key={index}
                      style={{
                        marginLeft: 8,
                        marginRight: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      target="blank"
                      href={item}
                    >
                      <img style={{ height: 56, width: 56 }} src={item} />
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          let removeIndex = index;
                          setImages(
                            images.filter((_, index) => index !== removeIndex)
                          );
                        }}
                      >
                        Удалить
                      </button>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
          <Button loading={loading} htmlType="submit" type="primary">
            Добавить
          </Button>
        </Form>
      </div>
    </>
  );
};

export default CreateObject;
